/* eslint-disable max-len */
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UnpackNestedValue, UseFormReturn } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { start } from '../../lib/saga-promise';
import routes from '../../constants/routes';
import { createGroup, editGroup } from '../../state/actions/group';
import RegisterForm from '../../component/Layout/RegisterForm';
import { RegisterInput, schemaEdit, schemaNew } from './types';
import TGTextField from '../../component/Elements/TGTextField';
import IsInvalidSwitch from '../../component/Form/IsInvalidSwitch';
import IsCanLogin from '../../component/Form/IsCanLogin';
import TGGrid from '../../component/Elements/TGGrid';
import CountrySelector from '../../component/Form/CountrySelector';
import { getCountries } from '../../state/actions/country';
import roles from '../../constants/roles';
import IsAgent from '../../component/Form/IsAgent';

export default function GroupCreate(_groupDetail: any = null) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useNavigate();
  const isEditMode: boolean = Object.keys(_groupDetail).length > 0;
  const schema = isEditMode ? schemaEdit : schemaNew;
  const countries = useSelector((state: RootStateOrAny) => state.country.allCountries);
  const user = useSelector((state: RootStateOrAny) => state.auth.user);
  const detail = useSelector((state: RootStateOrAny) => state.group.group);
  const initialRender = useRef(true);
  const [method, setMethod] = useState<UseFormReturn<RegisterInput, object>>();

  useEffect(() => {
    start(getCountries, { noLoading: true }, dispatch);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!initialRender.current && method) {
      // 自動採番されたユーザIDを表示する
      method.setValue('groupCode', detail.groupCode);
    } else {
      initialRender.current = false;
    }
  }, [detail, method]);

  const handleSubmit = async (
    data: UnpackNestedValue<RegisterInput>,
    _method: UseFormReturn<RegisterInput, object>
  ) => {
    if (isEditMode) {
      const editData = {
        ...data,
        groupId: _groupDetail.groupId,
      };
      await start(editGroup, editData, dispatch);
    } else {
      data.groupCode = '';
      await start(createGroup, data, dispatch);
      setMethod(_method);
    }
  };

  const [isAgentOn, setIsAgentOn] = useState(isEditMode ? _groupDetail.isAgent : false);
  const handleIsAgentChange = (isChecked: boolean) => {
    setIsAgentOn(isChecked);
  };

  return (
    <RegisterForm<RegisterInput, typeof schema>
      handleSubmit={handleSubmit}
      schema={schema}
      title={t('groupCreate.label.top')}
      isEditMode={isEditMode}
      handleCancel={() => history(routes.groupSearch)}
      // readOnly={user.roleId === roles.groupAdmin}
      options={{
        defaultValues: {
          groupCode: isEditMode ? _groupDetail.groupCode : t('userCreate.placeHolder.UserId'),
          groupName: isEditMode ? _groupDetail.groupName : '',
          department: isEditMode ? _groupDetail.department : '',
          groupNameFurigana: isEditMode ? _groupDetail.groupNameFurigana : '',
          countryId: isEditMode ? _groupDetail.countryId : '',
          postcode: isEditMode ? _groupDetail.postcode : '',
          address: isEditMode ? _groupDetail.address : '',
          phoneNumber: isEditMode ? _groupDetail.phoneNumber : '',
          groupNote: isEditMode ? _groupDetail.groupNote : '',
          isAgent: isEditMode ? _groupDetail.isAgent : false,
          distributorNumber: isEditMode ? _groupDetail.distributorNumber : '',
          distributorName: isEditMode ? _groupDetail.distributorName : '',
        },
      }}
    >
      {({ register, setValue, formState: { errors }, watch }) => (
        <TGGrid container spacing={2} direction="row" justifyContent="start" alignItems="flex-start">
          <TGGrid item xs={12} sm={12}>
            <TGTextField
              label={t('common.label.groupid')}
              isDisabled
              registration={register('groupCode')}
              // isError={'groupCode' in errors}
              // errorMessage={errors.groupCode?.message}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={6}>
            <TGTextField
              label={t('common.label.groupname')}
              registration={register('groupName')}
              isError={'groupName' in errors}
              errorMessage={errors.groupName?.message}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={6}>
            <TGTextField
              label={t('purchaseCompanyNameFurigana')}
              registration={register('groupNameFurigana')}
              isError={'groupNameFurigana' in errors}
              errorMessage={errors.groupNameFurigana?.message}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={6}>
            <TGTextField
              label={t('purchaseBuyerInformation.success.label.applicationDepartment')}
              registration={register('department')}
              isError={'department' in errors}
              errorMessage={errors.department?.message}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={6}>
            <CountrySelector
              countries={countries}
              countryId={watch('countryId')}
              handleChange={(data) => setValue('countryId', data?.countryId ?? '')}
              error={'countryId' in errors}
              message={errors.countryId?.message}
            />
          </TGGrid>

          <TGGrid item xs={12} sm={6}>
            <TGTextField
              label={t('common.label.postcode')}
              registration={register('postcode')}
              isError={'postcode' in errors}
              errorMessage={errors.postcode?.message}
            />
          </TGGrid>

          <TGGrid item xs={12} sm={6}>
            <TGTextField
              label={t('common.label.address')}
              registration={register('address')}
              isError={'address' in errors}
              errorMessage={errors.address?.message}
            />
          </TGGrid>

          <TGGrid item xs={12} sm={6}>
            <TGTextField
              inputProps={{
                maxLength: 11,
              }}
              label={t('common.label.phoneNumber')}
              registration={register('phoneNumber')}
              isError={'phoneNumber' in errors}
              errorMessage={errors.phoneNumber?.message}
            />
          </TGGrid>

          {(user.roleId === roles.systemAdmin) && (
          <TGGrid item xs={12} sm={6}>
            <IsAgent
              defaultChecked={!!(isEditMode && _groupDetail.isAgent)}
              registration={register('isAgent')}
              onChange={handleIsAgentChange}
            />
          </TGGrid>
          )}
          {(user.roleId === roles.systemAdmin) && (
          <TGGrid item xs={12} sm={6}>
            <TGTextField
              label={t('common.label.distributorNumber')}
              registration={register('distributorNumber')}
              isError={'distributorNumber' in errors}
              errorMessage={errors.distributorNumber?.message}
              disabled={!isAgentOn}
            />
          </TGGrid>
          )}
          {(user.roleId === roles.systemAdmin) && (
          <TGGrid item xs={12} sm={6}>
            <TGTextField
              label={t('common.label.distributorName')}
              registration={register('distributorName')}
              isError={'distributorName' in errors}
              errorMessage={errors.distributorName?.message}
              disabled={!isAgentOn}
            />
          </TGGrid>
          )}

          {(user.roleId === roles.systemAdmin) && (
            <TGGrid item xs={12} sm={6}>
              <IsInvalidSwitch
                defaultChecked={!!(isEditMode && _groupDetail.isInvalid)}
                registration={register('isInvalid')}
              />
            </TGGrid>
          )}

          {(user.roleId === roles.systemAdmin) && (
            <TGGrid item xs={12} sm={6}>
              <IsCanLogin
                defaultChecked={!!(isEditMode && _groupDetail.isCanLogin)}
                registration={register('IsCanLogin')}
              />
            </TGGrid>
          )}

          {(user.roleId === roles.systemAdmin) && (
            <TGGrid item xs={12} sm={12}>
              <TGGrid item sm={12}>
                <TGTextField
                  label={t('csvFormat.label.description')}
                  type="text"
                  registration={register('groupNote')}
                  // errorMessage={errors.versionReleaseNoteEngVer?.message}
                  multiline
                  rows={6}
                  maxRows={6}
                />
              </TGGrid>
            </TGGrid>
          )}

          {isEditMode && _groupDetail.loginId && (
            <Grid item xs={12} sm={12}>
              <Typography>
                <AccountBoxIcon color="success" sx={{ verticalAlign: 'bottom', mr: 0.5 }} />
                {`${t('common.label.role2')} :  ${_groupDetail.username} < ${_groupDetail.loginId} >`}
              </Typography>
            </Grid>
          )}
        </TGGrid>
      )}
    </RegisterForm>
  );
}
