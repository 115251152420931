import { call, put, takeLatest } from 'redux-saga/effects';
import LicenseServices from '../../services/license';
import {
  types,
  searchLicensesSuccess,
  mailLicensesPaymentSuccess,
  mailLicensesEndDateTrial10DaySuccess,
  mailLicensesEnddate60DaySuccess,
  sendMailLicensesEndDateTrial10DaySuccess,
  sendMailLicensesEndDate60DaySuccess,
  sendMailRegisterLicenseSuccess,
  sendMailLicensePaymentSuccess,
  licenseDetailSuccess,
  editLicenseSuccess,
  createLicenseSuccess,
  licenseImportUserSuccess,
  licenseNewKeySuccess,
  getLicensesByUserSuccess,
  getLicensesByTemporaryIdSuccess2,
  editLicensesStatusSuccess,
  getLicensesTrialByUserSuccess,
  updateStopLicensesStatusSuccess,
  updateStartLicensesStatusSuccess,
  reopenLicensesStatusSuccess,
  getStatusLicensesByUserLoginSuccess,
  getRenewalDateSuccess,
  getLicensesBankUpdateSuccess,
  updateLicensesBankSuccess,
  getListLicenseStopSuccess
} from '../actions/license';
import { sagaPromise } from '../../lib/saga-promise';
import { changeLoading } from '../actions/app';

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

interface Iaction {
  type: string;
  payload: any;
}

function* createLicense(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.createLicense, action.payload);
    yield put(createLicenseSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* editLicense(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.editLicense, action.payload);
    yield put(editLicenseSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* searchLicenses(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.searchLicenses, action.payload);
    yield put(searchLicensesSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* mailLicensesPayment(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.mailLicensesPayment, action.payload);
    yield put(mailLicensesPaymentSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* mailLicensesEndDateTrial10Day(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.mailLicensesEndDateTrial10Day, action.payload);
    yield put(mailLicensesEndDateTrial10DaySuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* mailLicenseEndDate60day(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.mailLicenseEndDate60day, action.payload);
    yield put(mailLicensesEnddate60DaySuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* sendMailLicensesEndDateTrial10Day(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.sendMailLicensesEndDateTrial10Day, action.payload);
    yield put(sendMailLicensesEndDateTrial10DaySuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* sendMailLicensesEndDate60Day(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.sendMailLicensesEndDate60Day, action.payload);
    yield put(sendMailLicensesEndDate60DaySuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* sendMailRegisterLicense(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.sendMailRegisterLicense, action.payload);
    yield put(sendMailRegisterLicenseSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* sendMailLicensePayment(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.sendMailLicensePayment, action.payload);
    yield put(sendMailLicensePaymentSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

/* eslint-disable max-len */
function* searchLicensesInPurchase(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.searchLicensesInPurchase, action.payload);
    yield put(searchLicensesSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* licenseDetail(action: Iaction) {
  try {
    if (!action.payload.noLoading) {
      // yield put(changeLoading(true));
    }
    const data: ResponseGenerator = yield call(LicenseServices.licenseDetail, action.payload);
    yield put(licenseDetailSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* licenseDetailInPurchase(action: Iaction) {
  try {
    if (!action.payload.noLoading) {
      // yield put(changeLoading(true));
    }
    const data: ResponseGenerator = yield call(LicenseServices.licenseDetailInPurchase, action.payload);
    yield put(licenseDetailSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* licenseImportUser(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.licenseImportUser, action.payload);
    yield put(licenseImportUserSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* getNewKeyLicense(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.licenseNewKey, action.payload);
    yield put(licenseNewKeySuccess(data));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* getLicensesByUser(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.getLicensesByUser, action.payload);
    yield put(getLicensesByUserSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* getListLicenseStop(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.getListLicenseStop, action.payload);
    yield put(getListLicenseStopSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* editStatusLicenses(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.editStatusLicenses, action.payload);
    yield put(editLicensesStatusSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* updateStopLicensesStatus(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.updateStopLicensesStatus, action.payload);
    yield put(updateStopLicensesStatusSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* updateStartLicensesStatus(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.updateStartLicensesStatus, action.payload);
    yield put(updateStartLicensesStatusSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* reopenLicensesStatus(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.reopenLicensesStatus, action.payload);
    yield put(reopenLicensesStatusSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* getLicensesByTemporaryId(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.getLicensesByTemporaryId, action.payload);
    yield put(getLicensesByTemporaryIdSuccess2(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* getLicensesTrialByUser(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.getLicensesTrialByUser, action.payload);
    yield put(getLicensesTrialByUserSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* getLicenseStatusByUserLogin() {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.getStatusLicensesByUserLogin);
    yield put(getStatusLicensesByUserLoginSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* getRenewalDate(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.getRenewalDate, action.payload);
    yield put(getRenewalDateSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* getLicensesBankUpdate(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.getLicensesBankUpdate, action.payload);
    yield put(getLicensesBankUpdateSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* updateLicensesBank(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.updateLicensesBank, action.payload);
    yield put(updateLicensesBankSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* changePaymentMethod(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.changePaymentMethod, action.payload);
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

export default function* licenseSaga() {
  yield takeLatest(types.CREATE_LICENSE, sagaPromise(createLicense));
  yield takeLatest(types.EDIT_LICENSE, sagaPromise(editLicense));
  yield takeLatest(types.SEARCH_LICENSES, sagaPromise(searchLicenses));
  yield takeLatest(types.MAIL_LICENSES_END_DATE_TRIAL_10_DAY, sagaPromise(mailLicensesEndDateTrial10Day));
  yield takeLatest(types.MAIL_LICENSES_PAYMENT, sagaPromise(mailLicensesPayment));
  yield takeLatest(types.MAIL_LICENSES_ENDDATE_60_DAY, sagaPromise(mailLicenseEndDate60day));
  yield takeLatest(types.SEND_MAIL_LICENSES_END_DATE_TRIAL_10_DAY, sagaPromise(sendMailLicensesEndDateTrial10Day));
  yield takeLatest(types.SEND_MAIL_LICENSES_ENDDATE_60_DAY, sagaPromise(sendMailLicensesEndDate60Day));
  yield takeLatest(types.SEND_MAIL_REGISTER_LICENSE, sagaPromise(sendMailRegisterLicense));
  yield takeLatest(types.SEND_MAIL_LICENSE_PAYMENT, sagaPromise(sendMailLicensePayment));
  yield takeLatest(types.SEARCH_LICENSES_IN_PURCHASE, sagaPromise(searchLicensesInPurchase));
  yield takeLatest(types.LICENSE_DETAIL, sagaPromise(licenseDetail));
  yield takeLatest(types.LICENSE_DETAIL_IN_PURCHASE, sagaPromise(licenseDetailInPurchase));
  yield takeLatest(types.LICENSE_IMPORT_USER, sagaPromise(licenseImportUser));
  yield takeLatest(types.LICENSE_NEW_KEY, sagaPromise(getNewKeyLicense));
  yield takeLatest(types.GET_LICENSES_BY_USER, sagaPromise(getLicensesByUser));
  yield takeLatest(types.GET_LIST_LICENSES_STOP, sagaPromise(getListLicenseStop));
  yield takeLatest(types.GET_LICENSES_TRIAL_BY_USER, sagaPromise(getLicensesTrialByUser));
  yield takeLatest(types.EDIT_LICENSES_STATUS, sagaPromise(editStatusLicenses));
  yield takeLatest(types.UPDATESTOP_LICENSES_STATUS, sagaPromise(updateStopLicensesStatus));
  yield takeLatest(types.UPDATESTART_LICENSES_STATUS, sagaPromise(updateStartLicensesStatus));
  yield takeLatest(types.REOPEN_LICENSES_STATUS, sagaPromise(reopenLicensesStatus));
  yield takeLatest(types.GET_LICENSES_BY_TEMPORARYID_SUCCESS, sagaPromise(getLicensesByTemporaryId));
  yield takeLatest(types.GET_STATUS_LICENSES_BY_USER_LOGIN, sagaPromise(getLicenseStatusByUserLogin));
  yield takeLatest(types.GET_RENEWAL_DATE, sagaPromise(getRenewalDate));
  yield takeLatest(types.GET_LICENSES_BANK_UPDATE, sagaPromise(getLicensesBankUpdate));
  yield takeLatest(types.UPDATE_LICENSES_BANK, sagaPromise(updateLicensesBank));
  yield takeLatest(types.CHANGE_PAYMENT_METHOD, sagaPromise(changePaymentMethod));
}
