export const types = {
  CREATE_LICENSE: 'CREATE_LICENSE',
  CREATE_LICENSE_SUCCESS: 'CREATE_LICENSE_SUCCESS',
  EDIT_LICENSE: 'EDIT_LICENSE',
  EDIT_LICENSE_SUCCESS: 'EDIT_LICENSE_SUCCESS',
  SEARCH_LICENSES: 'SEARCH_LICENSES',
  MAIL_LICENSES_END_DATE_TRIAL_10_DAY: 'MAIL_LICENSES_END_DATE_TRIAL_10_DAY',
  MAIL_LICENSES_ENDDATE_60_DAY: 'MAIL_LICENSES_ENDDATE_60_DAY',
  SEND_MAIL_LICENSES_END_DATE_TRIAL_10_DAY: 'SEND_MAIL_LICENSES_END_DATE_TRIAL_10_DAY',
  SEND_MAIL_LICENSES_ENDDATE_60_DAY: 'SEND_MAIL_LICENSES_ENDDATE_60_DAY',
  SEND_MAIL_REGISTER_LICENSE: 'SEND_MAIL_REGISTER_LICENSE',
  SEND_MAIL_LICENSE_PAYMENT: 'SEND_MAIL_LICENSE_PAYMENT',
  MAIL_LICENSES_PAYMENT: 'MAIL_LICENSES_PAYMENT',
  MAIL_LICENSES_PAYMENT_SUCCESS: 'MAIL_LICENSES_PAYMENT_SUCCESS',
  SEARCH_LICENSES_IN_PURCHASE: 'SEARCH_LICENSES_IN_PURCHASE',
  SEARCH_LICENSES_SUCCESS: 'SEARCH_LICENSES_SUCCESS',
  MAIL_LICENSES_END_DATE_TRIAL_10_DAY_SUCCESS: 'MAIL_LICENSES_END_DATE_TRIAL_10_DAY_SUCCESS',
  MAIL_LICENSES_ENDDATE_60_DAY_SUCCESS: 'MAIL_LICENSES_ENDDATE_60_DAY_SUCCESS',
  SEND_MAIL_REGISTER_LICENSE_SUCCESS: 'SEND_MAIL_REGISTER_LICENSE_SUCCESS',
  SEND_MAIL_LICENSE_PAYMENT_SUCCESS: 'SEND_MAIL_LICENSE_PAYMENT_SUCCESS',
  SEND_MAIL_LICENSES_END_DATE_TRIAL_10_DAY_SUCCESS: 'SEND_MAIL_LICENSES_END_DATE_TRIAL_10_DAY_SUCCESS',
  SEND_MAIL_LICENSES_ENDDATE_60_DAY_SUCCESS: 'SEND_MAIL_LICENSES_SUCCESS',
  LICENSE_DETAIL: 'LICENSE_DETAIL',
  LICENSE_DETAIL_IN_PURCHASE: 'LICENSE_DETAIL_IN_PURCHASE',
  LICENSE_DETAIL_SUCCESS: 'LICENSE_DETAIL_SUCCESS',
  LICENSE_IMPORT_USER: 'LICENSE_IMPORT_USER',
  LICENSE_IMPORT_USER_SUCCESS: 'LICENSE_IMPORT_USER',
  LICENSE_NEW_KEY: 'LICENSE_NEW_KEY',
  LICENSE_NEW_KEY_SUCCESS: 'LICENSE_NEW_KEY_SUCCESS',
  GET_LICENSES_BY_USER: 'GET_LICENSES_BY_USER',
  GET_LICENSES_BY_USER_SUCCESS: 'GET_LICENSES_BY_USER_SUCCESS',
  EDIT_LICENSES_STATUS: 'EDIT_LICENSES_STATUS',
  EDIT_LICENSES_STATUS_SUCCESS: 'EDIT_LICENSES_STATUS_SUCCESS',
  GET_LICENSES_BY_TEMPORARYID_SUCCESS: 'GET_LICENSES_BY_TEMPORARYID_SUCCESS',
  GET_LICENSES_BY_TEMPORARYID_SUCCESS2: 'GET_LICENSES_BY_TEMPORARYID_SUCCESS2',
  GET_LICENSES_TRIAL_BY_USER: 'GET_LICENSES_TRIAL_BY_USER',
  GET_LICENSES_TRIAL_BY_USER_SUCCESS: 'GET_LICENSES_TRIAL_BY_USER_SUCCESS',
  UPDATESTOP_LICENSES_STATUS: 'UPDATESTOP_LICENSES_STATUS',
  UPDATESTOP_LICENSES_STATUS_SUCCESS: 'UPDATESTOP_LICENSES_STATUS_SUCCESS',
  UPDATESTART_LICENSES_STATUS: 'UPDATESTART_LICENSES_STATUS',
  UPDATESTART_LICENSES_STATUS_SUCCESS: 'UPDATESTART_LICENSES_STATUS_SUCCESS',
  REOPEN_LICENSES_STATUS: 'REOPEN_LICENSES_STATUS',
  REOPEN_LICENSES_STATUS_SUCCESS: 'REOPEN_LICENSES_STATUS_SUCCESS',
  GET_STATUS_LICENSES_BY_USER_LOGIN: 'GET_STATUS_LICENSES_BY_USER_LOGIN',
  GET_STATUS_LICENSES_BY_USER_LOGIN_SUCCESS: 'GET_STATUS_LICENSES_BY_USER_SUCCESS_LOGIN',
  GET_RENEWAL_DATE: 'GET_RENEWAL_DATE',
  GET_RENEWAL_DATE_SUCCESS: 'GET_RENEWAL_DATE_SUCCESS',
  GET_LICENSES_BANK_UPDATE: 'GET_LICENSES_BANK_UPDATE',
  GET_LICENSES_BANK_UPDATE_SUCCESS: 'GET_LICENSES_BANK_UPDATE_SUCCESS',
  UPDATE_LICENSES_BANK: 'UPDATE_LICENSES_BANK',
  UPDATE_LICENSES_BANK_SUCCESS: 'UPDATE_LICENSES_BANK_SUCCESS',
  GET_LIST_LICENSES_STOP: 'GET_LIST_LICENSES_STOP',
  GET_LIST_LICENSES_STOP_SUCCESS: 'GET_LIST_LICENSES_STOP_SUCCESS',
  CHANGE_PAYMENT_METHOD: 'CHANGE_PAYMENT_METHOD',
};

export const createLicense = (payload: any) => ({
  type: types.CREATE_LICENSE,
  payload,
});

export const createLicenseSuccess = (payload: any) => ({
  type: types.CREATE_LICENSE_SUCCESS,
  payload,
});

export const editLicense = (payload: any) => ({
  type: types.EDIT_LICENSE,
  payload,
});

export const editLicenseSuccess = (payload: any) => ({
  type: types.EDIT_LICENSE_SUCCESS,
  payload,
});

export const searchLicenses = (payload: any) => ({
  type: types.SEARCH_LICENSES,
  payload,
});

export const mailLicensesEndDateTrial10Day = (payload: any) => ({
  type: types.MAIL_LICENSES_END_DATE_TRIAL_10_DAY,
  payload,
});

export const mailLicenseEndDate60day = (payload: any) => ({
  type: types.MAIL_LICENSES_ENDDATE_60_DAY,
  payload,
});

export const sendMailLicensesEndDateTrial10Day = (payload: any) => ({
  type: types.SEND_MAIL_LICENSES_END_DATE_TRIAL_10_DAY,
  payload,
});

export const sendMailLicensesEndDate60Day = (payload: any) => ({
  type: types.SEND_MAIL_LICENSES_ENDDATE_60_DAY,
  payload,
});

export const sendMailRegisterLicense = (payload: any) => ({
  type: types.SEND_MAIL_REGISTER_LICENSE,
  payload,
});

export const sendMailLicensePayment = (payload: any) => ({
  type: types.SEND_MAIL_LICENSE_PAYMENT,
  payload,
});

export const searchLicensesInPurchase = (payload: any) => ({
  type: types.SEARCH_LICENSES_IN_PURCHASE,
  payload,
});

export const searchLicensesSuccess = (payload: any) => ({
  type: types.SEARCH_LICENSES_SUCCESS,
  payload,
});
export const mailLicensesEndDateTrial10DaySuccess = (payload: any) => ({
  type: types.MAIL_LICENSES_END_DATE_TRIAL_10_DAY_SUCCESS,
  payload,
});

export const mailLicensesEnddate60DaySuccess = (payload: any) => ({
  type: types.MAIL_LICENSES_ENDDATE_60_DAY_SUCCESS,
  payload,
});

export const sendMailLicensesEndDateTrial10DaySuccess = (payload: any) => ({
  type: types.SEND_MAIL_LICENSES_END_DATE_TRIAL_10_DAY_SUCCESS,
  payload,
});

export const sendMailLicensesEndDate60DaySuccess = (payload: any) => ({
  type: types.SEND_MAIL_LICENSES_ENDDATE_60_DAY_SUCCESS,
  payload,
});

export const sendMailRegisterLicenseSuccess = (payload: any) => ({
  type: types.SEND_MAIL_REGISTER_LICENSE_SUCCESS,
  payload,
});

export const sendMailLicensePaymentSuccess = (payload: any) => ({
  type: types.SEND_MAIL_LICENSE_PAYMENT_SUCCESS,
  payload,
});

export const mailLicensesPayment = (payload: any) => ({
  type: types.MAIL_LICENSES_PAYMENT,
  payload,
});

export const mailLicensesPaymentSuccess = (payload: any) => ({
  type: types.MAIL_LICENSES_PAYMENT_SUCCESS,
  payload,
});

export const licenseDetail = (payload: any) => ({
  type: types.LICENSE_DETAIL,
  payload,
});

export const licenseDetailInPurchase = (payload: any) => ({
  type: types.LICENSE_DETAIL_IN_PURCHASE,
  payload,
});

export const licenseDetailSuccess = (payload: any) => ({
  type: types.LICENSE_DETAIL_SUCCESS,
  payload,
});

export const licenseImportUser = (payload: any) => ({
  type: types.LICENSE_IMPORT_USER,
  payload,
});

export const licenseImportUserSuccess = (payload: any) => ({
  type: types.LICENSE_IMPORT_USER_SUCCESS,
  payload,
});

export const licenseNewKey = (payload: any) => ({
  type: types.LICENSE_NEW_KEY,
  payload,
});

export const licenseNewKeySuccess = (payload: any) => ({
  type: types.LICENSE_NEW_KEY_SUCCESS,
  payload,
});

export const getLicensesByUser = (payload: any) => ({
  type: types.GET_LICENSES_BY_USER,
  payload,
});

export const getLicensesByUserSuccess = (payload: any) => ({
  type: types.GET_LICENSES_BY_USER_SUCCESS,
  payload,
});

export const getListLicenseStop = (payload: any) => ({
  type: types.GET_LIST_LICENSES_STOP,
  payload,
});

export const getListLicenseStopSuccess = (payload: any) => ({
  type: types.GET_LIST_LICENSES_STOP_SUCCESS,
  payload,
});

export const getLicensesByTemporaryIdSuccess = (payload: any) => ({
  type: types.GET_LICENSES_BY_TEMPORARYID_SUCCESS,
  payload,
});
export const getLicensesByTemporaryIdSuccess2 = (payload: any) => ({
  type: types.GET_LICENSES_BY_TEMPORARYID_SUCCESS2,
  payload,
});
export const editLicensesStatus = (payload: any) => ({
  type: types.EDIT_LICENSES_STATUS,
  payload,
});

export const editLicensesStatusSuccess = (payload: any) => ({
  type: types.EDIT_LICENSES_STATUS_SUCCESS,
  payload,
});
export const updateStopLicensesStatus = (payload: any) => ({
  type: types.UPDATESTOP_LICENSES_STATUS,
  payload,
});

export const updateStopLicensesStatusSuccess = (payload: any) => ({
  type: types.UPDATESTOP_LICENSES_STATUS_SUCCESS,
  payload,
});

export const updateStartLicensesStatus = (payload: any) => ({
  type: types.UPDATESTART_LICENSES_STATUS,
  payload,
});

export const updateStartLicensesStatusSuccess = (payload: any) => ({
  type: types.UPDATESTART_LICENSES_STATUS_SUCCESS,
  payload,
});

export const reopenLicensesStatus = (payload: any) => ({
  type: types.REOPEN_LICENSES_STATUS,
  payload,
});

export const reopenLicensesStatusSuccess = (payload: any) => ({
  type: types.REOPEN_LICENSES_STATUS_SUCCESS,
  payload,
});

export const getLicensesTrialByUser = (payload: any) => ({
  type: types.GET_LICENSES_TRIAL_BY_USER,
  payload,
});

export const getLicensesTrialByUserSuccess = (payload: any) => ({
  type: types.GET_LICENSES_TRIAL_BY_USER_SUCCESS,
  payload,
});

export const getStatusLicensesByUserLogin = () => ({
  type: types.GET_STATUS_LICENSES_BY_USER_LOGIN,
});

export const getStatusLicensesByUserLoginSuccess = (payload: any) => ({
  type: types.GET_STATUS_LICENSES_BY_USER_LOGIN_SUCCESS,
  payload,
});

export const getRenewalDate = (payload: any) => ({
  type: types.GET_RENEWAL_DATE,
  payload,
});

export const getRenewalDateSuccess = (payload: any) => ({
  type: types.GET_RENEWAL_DATE_SUCCESS,
  payload,
});

export const getLicensesBankUpdate = (payload: any) => ({
  type: types.GET_LICENSES_BANK_UPDATE,
  payload,
});

export const getLicensesBankUpdateSuccess = (payload: any) => ({
  type: types.GET_LICENSES_BANK_UPDATE_SUCCESS,
  payload,
});

export const updateLicensesBank = (payload: any) => ({
  type: types.UPDATE_LICENSES_BANK,
  payload,
});

export const updateLicensesBankSuccess = (payload: any) => ({
  type: types.UPDATE_LICENSES_BANK_SUCCESS,
  payload,
});

export const changePaymentMethod = (payload: any) => ({
  type: types.CHANGE_PAYMENT_METHOD,
  payload,
});
